<template>
  <div class="row justify-content-between mt-2">
    <div
      v-if="showStatistics(benchmarkThicknessMeasurements)"
      class="card col mt-2 text-center"
      style="height: auto"
    >
      <h6 class="font-weight-bolder">Initial Benchmark</h6>
      <p class="small mt-0 mb-0 px-0">Std Dev: {{ getThicknessMeasurementsStd(benchmarkThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Mean: {{ getThicknessMeasurementsMean(benchmarkThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Min: {{ measurementsMin(benchmarkThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Max: {{ measurementsMax(benchmarkThicknessMeasurements) }}</p>
    </div>
    <div
      v-if="showStatistics(gunsEqualizationThicknessMeasurements)"
      class="card col text-center m-1 mt-2"
      style="height: auto"
    >
      <h6 class="font-weight-bolder">{{ guns_equalization_title }}</h6>
      <p
        class="small mb-0 px-0"
        :class="{
          'font-weight-bolder':
            getStdDifferencePercentage(gunsEqualizationThicknessMeasurements, benchmarkThicknessMeasurements) !==
              null &&
            !reciprocatorThicknessMeasurements &&
            !thicknessadjustmentThicknessMeasurements,
        }"
      >
        Std Dev: {{ getThicknessMeasurementsStd(gunsEqualizationThicknessMeasurements) }}
        <span
          v-if="
            getStdDifferencePercentage(gunsEqualizationThicknessMeasurements, benchmarkThicknessMeasurements) !==
              null &&
            !reciprocatorThicknessMeasurements &&
            !thicknessadjustmentThicknessMeasurements
          "
        >
          <span class="badge bg-success text-dark">
            {{ getStdDifferencePercentage(gunsEqualizationThicknessMeasurements, benchmarkThicknessMeasurements) }}%
          </span>
        </span>
      </p>
      <p class="small mt-0 mb-0 px-0">
        Mean: {{ getThicknessMeasurementsMean(gunsEqualizationThicknessMeasurements) }}
      </p>
      <p class="small mt-0 mb-0 px-0">Min: {{ measurementsMin(gunsEqualizationThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Max: {{ measurementsMax(gunsEqualizationThicknessMeasurements) }}</p>
    </div>
    <div
      v-if="showStatistics(reciprocatorThicknessMeasurements)"
      class="card col mt-2 text-center m-1"
      style="height: auto"
    >
      <h6 class="font-weight-bolder">Reciprocator Optimization</h6>
      <p
        class="small mt-0 mb-0 px-0"
        :class="{
          'font-weight-bolder':
            getStdDifferencePercentage(reciprocatorThicknessMeasurements, benchmarkThicknessMeasurements) !== null &&
            gunsEqualizationThicknessMeasurements &&
            !thicknessadjustmentThicknessMeasurements,
        }"
      >
        Std Dev: {{ getThicknessMeasurementsStd(reciprocatorThicknessMeasurements) }}
        <span
          v-if="
            getStdDifferencePercentage(reciprocatorThicknessMeasurements, benchmarkThicknessMeasurements) !== null &&
            gunsEqualizationThicknessMeasurements &&
            !thicknessadjustmentThicknessMeasurements
          "
        >
          <span class="badge bg-success text-dark">
            {{ getStdDifferencePercentage(reciprocatorThicknessMeasurements, benchmarkThicknessMeasurements) }}%
          </span>
        </span>
      </p>
      <p class="small mt-0 mb-0 px-0">Mean: {{ getThicknessMeasurementsMean(reciprocatorThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Min: {{ measurementsMin(reciprocatorThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Max: {{ measurementsMax(reciprocatorThicknessMeasurements) }}</p>
    </div>
    <div
      v-if="showStatistics(thicknessadjustmentThicknessMeasurements)"
      class="card col mt-2 text-center m-1"
      style="height: auto"
    >
      <h6 class="font-weight-bolder mb-1">Thickness Adjustment</h6>
      <p
        class="small mt-0 mb-0 px-0"
        :class="{
          'font-weight-bolder':
            getStdDifferencePercentage(thicknessadjustmentThicknessMeasurements, benchmarkThicknessMeasurements) !== null,
        }"
      >
        Std Dev: {{ getThicknessMeasurementsStd(thicknessadjustmentThicknessMeasurements) }}
        <span
          v-if="
            getStdDifferencePercentage(thicknessadjustmentThicknessMeasurements, benchmarkThicknessMeasurements) !== null &&
            gunsEqualizationThicknessMeasurements &&
            reciprocatorThicknessMeasurements
          "
        >
          <span class="badge bg-success text-dark">
            {{ getStdDifferencePercentage(thicknessadjustmentThicknessMeasurements, benchmarkThicknessMeasurements) }}%
          </span>
        </span>
      </p>
      <p class="small mt-0 mb-0 px-0">Mean: {{ getThicknessMeasurementsMean(thicknessadjustmentThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Min: {{ measurementsMin(thicknessadjustmentThicknessMeasurements) }}</p>
      <p class="small mt-0 mb-0 px-0">Max: {{ measurementsMax(thicknessadjustmentThicknessMeasurements) }}</p>
    </div>
  </div>
</template>

<script>
import {
  getThicknessMeasurementsMean,
  getThicknessMeasurementsStd,
  measurementsMin,
  measurementsMax,
  showStatistics,
  getStdDifferencePercentage,
} from "@/views/composables.js";

export default {
  name: "ThicknessMeasurementStatistic",

  props: {
    benchmarkThicknessMeasurements: {
      type: Array,
      required: false,
      default: null,
    },
    gunsEqualizationThicknessMeasurements: {
      type: Array,
      required: false,
      default: null,
    },
    reciprocatorThicknessMeasurements: {
      type: Array,
      required: false,
      default: null,
    },
    thicknessadjustmentThicknessMeasurements: {
      type: Array,
      required: false,
      default: null,
    },
    gunsEqualizationTitle: {
      type: String,
      required: false,
      default: "Guns Equalization",
    },
  },
  data() {
    return {
      guns_equalization_title: "Guns Equalization",
    };
  },
  mounted() {
    this.guns_equalization_title = this.gunsEqualizationTitle
  },
  methods: {
    getThicknessMeasurementsMean,
    getThicknessMeasurementsStd,
    measurementsMin,
    measurementsMax,
    showStatistics,
    getStdDifferencePercentage,
  },
};
</script>
