<template>
  <div class="py-1 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
          <div class="card-body mt-2">
            <h5 class="mb-0">{{ $t('Powder Amount List for line ') }}<b><i>{{ line.name }}</i></b></h5>
            <div v-if="powder_amount_list.length" >
              <div class="table-responsive">
                <table id="datatable-search" class="table table-flush table-hover">
                  <thead class="thead-light">
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="padding: 0.5rem 0.5rem;">
                        {{$t("Name")}}
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="padding: 0.5rem 0.5rem;">
                        {{$t("Powder Model")}}
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="padding: 0.5rem 0.5rem;">
                        {{$t("Created")}}
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style="padding: 0.5rem 0.5rem;">
                        {{$t("Remove Calibration")}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="powder_amount in OrderByDateUpdated(powder_amount_list)" :key="powder_amount.id">
                      <td
                        class="text-sm font-weight-normal clickable"
                        style="cursor: pointer; vertical-align: middle;"
                        :onclick="`window.location='/applications/flightpath/powderamountcalibrationslibrary/powderamountcalibration/${powder_amount.line}/${powder_amount.id}'`">
                          {{ powder_amount.name }}
                      </td>
                      <td
                        class="text-sm font-weight-normal clickable"
                        style="cursor: pointer; vertical-align: middle;"
                        :onclick="`window.location='/applications/flightpath/powderamountcalibrationslibrary/powderamountcalibration/${powder_amount.line}/${powder_amount.id}'`">
                          {{ getPowderName(powder_amount.powdermodel) }}
                      </td>
                      <td
                        class="text-sm font-weight-normal clickable text-center"
                        style="cursor: pointer; vertical-align: middle;"
                        :onclick="`window.location='/applications/flightpath/powderamountcalibrationslibrary/powderamountcalibration/${powder_amount.line}/${powder_amount.id}'`">
                          {{ dateToString(powder_amount.created_at, true) }}
                      </td>
                      <td class="text-sm font-weight-normal clickable"
                      :onclick="`deletePowderAmount(${powder_amount.id}, '${powder_amount.name}')`"
                      style="vertical-align: middle;">
                        <a
                          class="btn btn-link text-danger text-gradient px-3 mb-0 my-auto"
                        >
                          <i class="far fa-trash-alt me-2" aria-hidden="true"></i>{{$t('Delete')}}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <p> {{$t('No Powder Amount created.')}} </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useGetCalibratedPowderModels } from "@/views/composables.js";
import axios from 'axios'
import { dateToString, OrderByDateUpdated } from "@/views/composables.js";
import { DataTable } from "simple-datatables";


export default {
  name: "PowderAmountCalibrationsList",
  props: {
    lineId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { calibrated_powdermodels, getCalibratedPowderModels } = useGetCalibratedPowderModels();
    return { calibrated_powdermodels, getCalibratedPowderModels };
  },
  data(){
  this.$i18n.locale = this.$store.state.user_data.language;{
    return{
      powder_amount_list: [],
      line: [],
    }
  }},
  watch:{
    '$store.state.user_data.language' () {
      this.$i18n.locale = this.$store.state.user_data.language;
    }
  },
  mounted() {
    this.getData();
  },
  created() {
    window.deletePowderAmount = this.deletePowderAmount;
  },
  methods: {
    dateToString,
    OrderByDateUpdated,
    getPowderName(powdermodel_id) {
      let powdermodel = this.calibrated_powdermodels.find((powdermodel) => powdermodel.id == powdermodel_id);
  
      return powdermodel ? powdermodel.name : "";
    },
    async getData() {
      await Promise.all([this.getLine(), this.getPowderAmountList(), this.getCalibratedPowderModels(this.lineId)])
      this.$store.state.isLoading = false;

      if (this.powder_amount_list.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const dataTableSearch = new DataTable("#datatable-search", {
          searchable: true,
          fixedHeight: false,
        });
      }
    },
    async getLine() {
      try {
        let response = await axios.get("/api/v1/fp/line/" + this.lineId)
        this.line = response.data
      } catch (error) {
        console.error(error)
        setTimeout(function() {
          this.getLine();
        }.bind(this), 5000);
      }
    },
    async getPowderAmountList() {
      await axios
      .get('/api/v1/fp/powderamountcalibrationmeasures/',
          {
            params: {
                line: this.lineId,
                library_display: true
            }
          })
      .then(response => {
        this.powder_amount_list = JSON.parse(JSON.stringify(response.data));
      })
      .catch(error => {
        console.error(error)
        setTimeout(function() {
          this.getPowderAmountList();
        }.bind(this), 5000);
      })
    },
    async deletePowderAmount(powder_amount_id, powder_amount_name) {
      this.$swal({
        title: `${this.$t('Are you sure you want to delete Powder Amount Calibration')} ${powder_amount_name}?`,
        text: `${this.$t('You will not be able to revert this!')}`,
        showCancelButton: true,
        confirmButtonText: `${this.$t('Yes, delete it')}`,
        cancelButtonText: `${this.$t('No, cancel')}`,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      })
      .then(
        async (result)  => {
        if (result.isConfirmed) {
          this.$store.state.isLoading = true;
            await axios
            .patch(
              '/api/v1/fp/powderamountcalibrationmeasures/' + powder_amount_id + "/",
              {'library_display': false},
            )
            .then(() => {
              this.$store.state.isLoading = false;
              this.powderAmountDeletedSweetAlert();
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    },
    async powderAmountDeletedSweetAlert() {
      this.$swal({
        title: `${this.$t('Deleted')}`,
        text: `${this.$t('Your Powder Amount has been deleted.')}`,
        icon: "success",
        showCancelButton: false,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      })
      .then(async (result)  => {
        if (result.isConfirmed) {
          window.location.reload(true)
        }
      }
      );
    },
  }
};
</script>

<i18n>
  {
    "EN": {
        "Powder Amount List for line ":"Powder Amount List for line ",
        "Name":"Name",
        "Powder Model":"Powder Model",
        "Created":"Created",
        "Remove Calibration":"Remove Calibration",
        "Are you sure you want to delete Powder Amount Calibration":"Are you sure you want to delete Powder Amount Calibration",
        "You will not be able to revert this!":"You will not be able to revert this!",
        "Yes, delete it":"Yes, delete it",
        "No, cancel":"No, cancel",
        "Deleted":"Deleted",
        "Your Powder Amount has been deleted.":"Your Powder Amount has been deleted.",
        "Delete":"Delete",
        "No Powder Amount created.":"No Powder Amount created.",
    },
    "DE": {
        "Powder Amount List for line ":"Pulverbetrag Liste für Linie",
        "Name":"Name",
        "Powder Model":"Pulvermodell",
        "Created":"Erstellt",
        "Remove Calibration":"Kalibrierung entfernen",
        "Are you sure you want to delete Powder Amount Calibration":"Sind Sie sicher, dass Sie die Pulverbetrag Kalibrierung löschen möchten?",
        "You will not be able to revert this!":"Sie können dies nicht rückgängig machen!",
        "Yes, delete it":"Ja, lösche es",
        "No, cancel":"Nein, abbrechen",
        "Deleted":"Gelöscht",
        "Your Powder Amount has been deleted.":"Ihr Pulverbetrag wurde gelöscht.",
        "Delete":"Löschen",
        "No Powder Amount created.":"Kein Pulverbetrag erstellt.",
    },
    "ES": {
        "Powder Amount List for line ":"Lista de Flujos de Pintura para la línea ",
        "Name":"Nombre",
        "Powder Model":"Modelo de Pintura en Polvo",
        "Created":"Creada",
        "Remove Calibration":"Eliminar Calibración",
        "Are you sure you want to delete Powder Amount Calibration":"¿Estás seguro de que quieres eliminar la calibración",
        "You will not be able to revert this!":"¡No podrás deshacer esto!",
        "Yes, delete it":"Sí, bórrala",
        "No, cancel":"No, cancelar",
        "Deleted":"Eliminada",
        "Your Powder Amount has been deleted.":"Su calibración ha sido eliminada.",
        "Delete":"Eliminar",
        "No Powder Amount created.":"No existe ninguna calibración",
    },
    "FR": {
        "Powder Amount List for line ":"Liste des quantités de poudre pour la ligne",
        "Name":"Nom",
        "Powder Model":"Modèle de Poudre",
        "Created":"Créée",
        "Remove Calibration":"Supprimer l'optimisation",
        "Are you sure you want to delete Powder Amount Calibration":"Êtes-vous sûr de vouloir supprimer l'optimisation de la quantité de poudre",
        "You will not be able to revert this!":"Vous ne pourrez pas revenir en arrière!",
        "Yes, delete it":"Oui, supprimez-le",
        "No, cancel":"Non, annuler",
        "Deleted":"Supprimé",
        "Your Powder Amount has been deleted.":"Votre quantité de poudre a été supprimée.",
        "Delete":"Effacer",
        "No Powder Amount created.":"Aucune quantité de poudre créée.",
    },
    "IT": {
        "Powder Amount List for line ":"Elenco delle quantità di polvere per la linea",
        "Name":"Nome",
        "Powder Model":"Modello di polvere",
        "Created":"Creata",
        "Remove Calibration":"Rimuovi calibrazione",
        "Are you sure you want to delete Powder Amount Calibration":"Sei sicuro di voler eliminare la calibrazione della quantità di polvere",
        "You will not be able to revert this!":"Non sarai in grado di annullare questa operazione!",
        "Yes, delete it":"Sì, cancellalo",
        "No, cancel":"No, annulla",
        "Deleted":"Eliminato",
        "Your Powder Amount has been deleted.":"La tua quantità di polvere è stata eliminata.",
        "Delete":"Elimina",
        "No Powder Amount created.":"Nessuna quantità di polvere creata.",
    },
    "PL": {
        "Powder Amount List for line ":"Lista ilości proszku dla linii",
        "Name":"Nazwa",
        "Powder Model":"Model Proszku",
        "Created":"Utworzony",
        "Remove Calibration":"Usuń kalibrację",
        "Are you sure you want to delete Powder Amount Calibration":"Czy na pewno chcesz usunąć kalibrację ilości proszku",
        "You will not be able to revert this!":"Nie będziesz mógł tego cofnąć!",
        "Yes, delete it":"Tak, usuń to",
        "No, cancel":"Nie, anuluj",
        "Deleted":"Usunięty",
        "Your Powder Amount has been deleted.":"Twoja ilość proszku została usunięta.",
        "Delete":"Kasować",
        "No Powder Amount created.":"Nie utworzono żadnej ilości proszku.",
    },
    "TR": {
        "Powder Amount List for line ":"Hat için Toz Miktarı Listesi",
        "Name":"İsim",
        "Powder Model":"Toz Modeli",
        "Created":"Oluşturuldu",
        "Remove Calibration":"Kalibrasyonu Kaldır",
        "Are you sure you want to delete Powder Amount Calibration":"Toz Miktarı Kalibrasyonunu silmek istediğinizden emin misiniz?",
        "You will not be able to revert this!":"Bunu geri alamayacaksın!",
        "Yes, delete it":"Evet, sil",
        "No, cancel":"Hayır, iptal et",
        "Deleted":"Silindi",
        "Your Powder Amount has been deleted.":"Toz Miktarınız silindi.",
        "Delete":"Sil",
        "No Powder Amount created.":"Oluşturulan Toz Miktarı yok.",
    },
    "ZH": {
        "Powder Amount List for line ":"线路的粉量列表",
        "Name":"名称",
        "Powder Model":"粉末模型",
        "Created":"创建",
        "Remove Calibration":"删除校准",
        "Are you sure you want to delete Powder Amount Calibration":"您确定要删除粉量校准吗？",
        "You will not be able to revert this!":"您将无法撤消此操作！",
        "Yes, delete it":"是的，删除它",
        "No, cancel":"不，取消",
        "Deleted":"已删除",
        "Your Powder Amount has been deleted.":"您的粉量已被删除。",
        "Delete":"删除",
        "No Powder Amount created.":"未创建粉量。",
    }
  }
</i18n>