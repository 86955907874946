<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="mt-4 mx-4">
        <a class="text-bold">{{ $t("Language") }}:&nbsp;</a>
        <select
          id="language-choices-category"
          v-model="this.$i18n.locale"
          name="language-choices"
          class="bg-transparent border-0 text-black"
        >
          <option
            v-for="(language_option, key) in languages"
            :key="key"
            :value="key"
            class="text-dark"
          >
            <span class="d-sm-inline d-none"> {{ language_option.emoji }} {{ key }} - {{ language_option.name }} </span>
          </option>
        </select>
      </div>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-success text-gradient">
                    {{ $t("Welcome back") }}
                  </h3>
                  <p class="mb-0">{{ $t("Enter your email and password to sign in") }}</p>
                </div>
                <div class="card-body">
                  <label>{{ $t("Email") }}</label>
                  <div class="mb-3">
                    <input
                      v-model="email"
                      :class="email_input_error ? `form-control is-invalid` : `form-control`"
                      type="email"
                      :placeholder="$t('Email')"
                      @input="
                        email_input_error = false;
                        email = email.toLowerCase();
                      "
                    />
                  </div>
                  <label>{{ $t("Password") }}</label
                  >&nbsp;
                  <i
                    :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                    style="font-size: 0.73em"
                    @click="showPassword = !showPassword"
                  >
                  </i>
                  <div class="mb-3">
                    <input
                      v-model="password"
                      :class="password_input_error ? `form-control is-invalid` : `form-control`"
                      :type="showPassword ? 'text' : 'password'"
                      :placeholder="$t('Password')"
                      @input="password_input_error = false"
                      @keyup.enter="SubmitSignIn"
                    />
                  </div>
                  <div class="m-2 text-xs">
                    {{ $t("Forgot your password?") }}
                    <router-link
                      :to="{ name: 'ResetPasswordByEmail' }"
                      tag="button"
                    >
                      {{ $t("Click here to reset it.") }}
                    </router-link>
                  </div>
                  <div class="text-center">
                    <button
                      class="w-100 mt-2 mb-2 btn bg-gradient-success ms-lg-auto me-lg-0 me-auto mt-lg-0"
                      variant="gradient"
                      color="success"
                      @click="SubmitSignIn"
                    >
                      {{ $t("Sign In") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage: 'url(' + require('@/assets/img/curved-images/curved8.jpg') + ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
const body = document.getElementsByTagName("body")[0];
import axios from "axios";
import { mapMutations } from "vuex";
import { validateEmail } from "@/views/composables.js";

export default {
  name: "SignIn",
  components: {},
  data() {
    this.$i18n.locale = "EN";
    return {
      email: "",
      password: "",
      showPassword: false,
      email_input_error: false,
      password_input_error: false,
      languages: {
        EN: { name: "English", emoji: "🇬🇧" },
        DE: { name: "Deutsch", emoji: "🇩🇪" },
        ES: { name: "Español", emoji: "🇪🇸" },
        FR: { name: "Français", emoji: "🇫🇷" },
        IT: { name: "Italiano", emoji: "🇮🇹" },
        PL: { name: "Polski", emoji: "🇵🇱" },
        TR: { name: "Türkçe", emoji: "🇹🇷" },
        ZH: { name: "中文", emoji: "🇨🇳" },
        // 'BG' : {'name': 'Bulgarian', 'emoji': '🇧🇬'},
        // 'CS' : {'name': 'Czech', 'emoji': '🇨🇿'},
        // 'DA' : {'name': 'Danish', 'emoji': '🇩🇰'},
        // 'EL' : {'name': 'Greek', 'emoji': '🇬🇷'},
        // 'ET' : {'name': 'Estonian', 'emoji': '🇪🇪'},
        // 'FI' : {'name': 'Finnish', 'emoji': '🇫🇮'},
        // 'HU' : {'name': 'Hungarian', 'emoji': '🇭🇺'},
        // 'ID' : {'name': 'Indonesian', 'emoji': '🇮🇩'},
        // 'JA' : {'name': 'Japanese', 'emoji': '🇯🇵'},
        // 'KO' : {'name': 'Korean', 'emoji': '🇰🇷'},
        // 'LT' : {'name': 'Lithuanian', 'emoji': '🇱🇹'},
        // 'LV' : {'name': 'Latvian', 'emoji': '🇱🇻'},
        // 'NB' : {'name': 'Norwegian', 'emoji': '🇳🇴'},
        // 'NL' : {'name': 'Dutch', 'emoji': '🇳🇱'},
        // 'PT' : {'name': 'Portuguese', 'emoji': '🇵🇹'},
        // 'RO' : {'name': 'Romanian', 'emoji': '🇷🇴'},
        // 'RU' : {'name': 'Russian', 'emoji': '🇷🇺'},
        // 'SK' : {'name': 'Slovak', 'emoji': '🇸🇰'},
        // 'SL' : {'name': 'Slovenian', 'emoji': '🇸🇮'},
        // 'SV' : {'name': 'Swedish', 'emoji': '🇸🇪'},
        // 'UK' : {'name': 'Ukrainian', 'emoji': '🇺🇦'},
      },
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted() {
    (this.$store.state.isLoading = false), window.addEventListener("keyup", this.handleEnterKey);
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
    window.removeEventListener("keyup", this.handleEnterKey);
  },
  methods: {
    validateEmail,
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    SubmitSignIn() {
      if (this.email == null || this.email == "" || this.password == null || this.password == "") {
        this.email_input_error = true;
        this.password_input_error = true;
        this.$swal({
          title: `${this.$t("Empty email or password field.")}`,
          text: `${this.$t("Please enter the email and password.")}`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }

      if (!this.validateEmail(this.email)) {
        this.email_input_error = true;
        this.$swal({
          title: `${this.$t("Invalid email format.")}`,
          text: `${this.$t("Please enter a valid email, with the format")} name@email.com`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }

      this.$store.state.isLoading = false;
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");

        // Retrieve CSRF token from cookies
      const getCookie = (name) => {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
      };

      const csrfToken = getCookie("csrftoken");

      axios
        .post("/api/v1/token/login/", {

          username: this.email,
          password: this.password,
        },
        {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
      )
        .then(response => {
          const token = response.data.auth_token;
          this.$store.commit("setToken", token);

          axios.defaults.headers.common["Authorization"] = "Token " + token;
          localStorage.setItem("token", token);

          const toPath = this.$route.query.to || "/";
          this.$router.push(toPath);
        })
        .catch(error => {
          console.error(error.response);
          this.$swal({
            title: `${this.$t("Error validating credentials.")}`,
            text: `${this.$t("Input correct credentials and try again.")}`,
            icon: "error",
            confirmButtonText: "OK",
          });
          this.email_input_error = true;
          this.password_input_error = true;
          this.email = "";
          this.password = "";
        });
    },
    handleEnterKey(e) {
      if (e.key === "Enter") {
        e.preventDefault();
        this.SubmitSignIn();
      }
    },
  },
};
</script>
<i18n>
  {
    "EN": {
      "Sign In": "Sign In",
      "Language": "Language",
      "Welcome back": "Welcome back",
      "Enter your email and password to sign in": "Enter your email and password to sign in",
      "Email": "Email",
      "Password": "Password",
      "Empty email or password field.": "Empty email or password field.",
      "Please enter the email and password.": "Please enter the email and password.",
      "Error validating credentials.": "Error validating credentials.",
      "Input correct credentials and try again.": "Input correct credentials and try again.",
      "Invalid email format.": "Invalid email format.",
      "Please enter a valid email, with the format": "Please enter a valid email, with the format",
      "Click here to reset it.": "Click here to reset it.",
      "Forgot your password?": "Forgot your password?",
    },
    "DE": {
      "Sign In": "Anmelden",
      "Language": "Sprache",
      "Welcome back": "Willkommen zurück",
      "Enter your email and password to sign in": "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich anzumelden",
      "Email": "E-Mail",
      "Password": "Passwort",
      "Empty email or password field.": "Leeres E-Mail oder Passwortfeld.",
      "Please enter the email and password.": "Bitte geben Sie die E-Mail-Adresse und das Passwort ein.",
      "Error validating credentials.": "Fehler beim Überprüfen der Anmeldeinformationen.",
      "Input correct credentials and try again.": "Geben Sie die richtigen Anmeldeinformationen ein und versuchen Sie es erneut.",
      "Invalid email format.": "Ungültiges E-Mail-Format.",
      "Please enter a valid email, with the format": "Bitte geben Sie eine gültige E-Mail-Adresse im Format ein",
      "Click here to reset it.": "Klicken Sie hier, um es zurückzusetzen.",
      "Forgot your password?": "Passwort vergessen?",
    },
    "ES": {
      "Sign In": "Iniciar Sesión",
      "Language": "Idioma",
      "Welcome back": "Bienvenido",
      "Enter your email and password to sign in": "Introduzca su correo electrónico y contraseña para iniciar sesión",
      "Email": "Email",
      "Password": "Contraseña",
      "Empty email or password field.": "Campos de correo electrónico o contraseña vacíos.",
      "Please enter the email and password.": "Por favor introduzca el correo electrónico y la contraseña.",
      "Error validating credentials.": "Error al validar las credenciales.",
      "Input correct credentials and try again.": "Introduzca las credenciales correctas e inténtelo de nuevo.",
      "Invalid email format.": "Formato de correo electrónico no válido.",
      "Please enter a valid email, with the format": "Por favor, introduzca un correo electrónico válido, con el formato",
      "Click here to reset it.": "Haga clic aquí para restablecerlo.",
      "Forgot your password?": "¿Olvidaste tu contraseña?",
    },
    "FR": {
      "Sign In": "Se connecter",
      "Language": "Langue",
      "Welcome back": "Bienvenue",
      "Enter your email and password to sign in": "Entrez votre email et votre mot de passe pour vous connecter",
      "Email": "Email",
      "Password": "Mot de passe",
      "Empty email or password field.": "Champ e-mail ou mot de passe vide.",
      "Please enter the email and password.": "Veuillez saisir l'e-mail et le mot de passe.",
      "Error validating credentials.": "Erreur de validation des informations d'identification.",
      "Input correct credentials and try again.": "Saisissez les informations d'identification correctes et réessayez.",
      "Invalid email format.": "Format d'e-mail invalide.",
      "Please enter a valid email, with the format": "Veuillez saisir un e-mail valide, avec le format",
      "Click here to reset it.": "Cliquez ici pour le réinitialiser.",
      "Forgot your password?": "Mot de passe oublié?",
    },
    "IT": {
      "Sign In": "Accedi",
      "Language": "Lingua",
      "Welcome back": "Bentornato",
      "Enter your email and password to sign in": "Inserisci la tua email e la tua password per accedere",
      "Email": "Email",
      "Password": "Password",
      "Empty email or password field.": "Campo email o password vuoto.",
      "Please enter the email and password.": "Inserisci l'email e la password.",
      "Error validating credentials.": "Errore di convalida delle credenziali.",
      "Input correct credentials and try again.": "Inserisci le credenziali corrette e riprova.",
      "Invalid email format.": "Formato email non valido.",
      "Please enter a valid email, with the format": "Inserisci un'email valida, con il formato",
      "Click here to reset it.": "Clicca qui per reimpostarlo.",
      "Forgot your password?": "Hai dimenticato la password?",
    },
    "PL": {
      "Sign In": "Zaloguj",
      "Language": "Język",
      "Welcome back": "Witamy z powrotem",
      "Enter your email and password to sign in": "Wpisz swój adres e-mail i hasło, aby się zalogować",
      "Email": "E-mail",
      "Password": "Hasło",
      "Empty email or password field.": "Puste pole e-mail lub hasło.",
      "Please enter the email and password.": "Proszę podać adres e-mail i hasło.",
      "Error validating credentials.": "Błąd weryfikacji poświadczeń.",
      "Input correct credentials and try again.": "Wprowadź poprawne dane logowania i spróbuj ponownie.",
      "Invalid email format.": "Nieprawidłowy format adresu e-mail.",
      "Please enter a valid email, with the format": "Wprowadź prawidłowy adres e-mail, w formacie",
      "Click here to reset it.": "Kliknij tutaj, aby zresetować.",
      "Forgot your password?": "Zapomniałeś hasła?",
    },
    "TR": {
      "Sign In": "Oturum Aç",
      "Language": "Dil",
      "Welcome back": "Tekrar Hoşgeldiniz",
      "Enter your email and password to sign in": "Oturum açmak için e-posta adresinizi ve şifrenizi girin",
      "Email": "E-posta",
      "Password": "Şifre",
      "Empty email or password field.": "Boş e-posta veya şifre alanı.",
      "Please enter the email and password.": "Lütfen e-posta ve şifreyi girin.",
      "Error validating credentials.": "Kimlik bilgileri doğrulanamadı.",
      "Input correct credentials and try again.": "Doğru kimlik bilgilerini girin ve tekrar deneyin.",
      "Invalid email format.": "Geçersiz e-posta formatı.",
      "Please enter a valid email, with the format": "Lütfen geçerli bir e-posta adresi girin, format",
      "Click here to reset it.": "Sıfırlamak için buraya tıklayın.",
      "Forgot your password?": "Şifrenizi mi unuttunuz?",
    },
    "ZH": {
      "Sign In": "登录",
      "Language": "语言",
      "Welcome back": "欢迎回来",
      "Enter your email and password to sign in": "输入您的电子邮件和密码登录",
      "Email": "电子邮件",
      "Password": "密码",
      "Empty email or password field.": "空电子邮件或密码字段。",
      "Please enter the email and password.": "请输入电子邮件和密码。",
      "Error validating credentials.": "验证凭据时出错。",
      "Input correct credentials and try again.": "输入正确的凭据，然后重试。",
      "Invalid email format.": "电子邮件格式无效。",
      "Please enter a valid email, with the format": "请输入有效的电子邮件，格式为",
      "Click here to reset it.": "单击此处重置。",
      "Forgot your password?": "忘记密码了吗？",
    },
  }
</i18n>
