<template>
  <div class="py-1 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card-body mt-2">
          <h5 class="mb-0">{{ tableTitle }}</h5>
          <div v-if="guns_equalization_list.length">
            <div class="table-responsive mt-4">
              <table class="table table-flush table-hover">
                <!-- <thead class="thead-light">
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs text-left font-weight-bolder opacity-7"
                      style="padding: 0.5rem 0.5rem"
                    >
                      Guns Equalizations by Date
                    </th>
                  </tr>
                </thead> -->
                <tbody>
                  <tr
                    v-for="guns_equalization in OrderByDateUpdated(guns_equalization_list)"
                    :key="guns_equalization.id"
                  >
                    <td
                      class="text-sm font-weight-normal clickable text-left"
                      style="cursor: pointer; vertical-align: middle"
                      @click="routeToGunsEqualization(lineId, guns_equalization.id);"
                    >
                      {{ dateToString(guns_equalization.updated_at, true) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else>
            <p class="ms-1">No Guns Equalization history</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { dateToString,OrderByDateUpdated } from "@/views/composables.js";

export default {
  name: "GunsEqualizationList",
  props: {
    lineId: {
      type: String,
      default: null,
    },
    visitId: {
      type: String,
      default: null,
    },
    tableTitle: {
      type: String,
      default: "Optimization List for Previous Visits",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    {
      return {
        guns_equalization_list: [],
      };
    }
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    dateToString,
    OrderByDateUpdated,
    async getData() {
      await this.getGunsEqualizationList();
      this.$store.state.isLoading = false;
    },
    async getGunsEqualizationList() {
      await axios
        .get("/api/v1/finetune/gunsequalizationsbyvisit/" + this.visitId + "/")
        .then(response => {
          this.guns_equalization_list = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    routeToGunsEqualization(lineId, gunsEqualizationId) {
      this.$router.push({
        name: 'GunsEqualizationFineTune',
        params: {
          lineId: lineId,
          gunsEqualizationId: gunsEqualizationId,
          fromHistory: true,
        },
      });
    },
  },
};
</script>

<i18n>
  {
    "EN": {
    },
    "DE": {
    },
    "ES": {
    },
    "FR": {
    },
    "IT": {
    },
    "PL": {
    },
    "TR": {
    },
    "ZH": {
    }
  }
</i18n>
