<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card-body text-start">
          <h5 class="mb-0">Spray Patterns calibrated</h5>
          <div
            v-if="CurrentVisitPowderModelsOrdered.length"
            class="table-responsive mt-4"
          >
            <table
              class="table table-flush table-hover"
            >
              <thead class="thead-light">
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    style="padding: 0.5rem 0.5rem"
                  >
                    Select
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    style="padding: 0.5rem 0.5rem"
                  >
                    {{ $t("Name") }}
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    style="padding: 0.5rem 0.5rem"
                  >
                    {{ $t("Created") }}
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    style="padding: 0.5rem 0.5rem"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="powdermodel in CurrentVisitPowderModelsOrdered"
                  :key="powdermodel.id"
                >
                  <td class="text-center">
                    <SoftCheckbox
                      :id="`checkbox-${powdermodel.id}`"
                      :name="`checkbox-${powdermodel.id}`"
                      :checked="visit.spraypatternstage.selected_powder_model == powdermodel.id"
                      :disabled="visit.spraypatternstage.selected_powder_model == powdermodel.id"
                      @change="updateSelectedPowderModel(powdermodel.id)"
                    />
                  </td>
                  <td
                    class="text-sm font-weight-normal clickable"
                    :onclick="`window.location='/applications/blueprint/powdermodel/calibrated/${powdermodel.id}'`"
                    style="cursor: pointer; vertical-align: middle"
                  >
                    {{ powdermodel.name }}
                  </td>
                  <td
                    class="text-sm font-weight-normal clickable"
                    :onclick="`window.location='/applications/blueprint/powdermodel/calibrated/${powdermodel.id}'`"
                    style="cursor: pointer; vertical-align: middle"
                  >
                    {{ dateToString(powdermodel.created_at, true) }}
                  </td>
                  <td
                    class="text-sm text-end font-weight-normal clickable"
                    :onclick="`deletePowderModel(${powdermodel.id}, '${powdermodel.name}')`"
                    style="vertical-align: middle"
                  >
                    <a class="btn btn-link text-danger text-gradient px-3 mb-0 my-auto text-end">
                      <i
                        class="far fa-trash-alt me-2"
                        aria-hidden="true"
                      ></i
                      >{{ $t("Delete") }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p class="font-light mt-3">
              No Spray Patterns calibrations. Please calibrate a new Spray Pattern.
            </p>
          </div>
          <h5 class="mt-4 mb-0">Spray Patterns calibrated on previous Visits</h5>
          <div v-if="previousVisitsPowderModelsOrdered.length" class="table-responsive mt-4">
            <table class="table table-flush table-hover">
              <thead class="thead-light">
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    style="padding: 0.5rem 0.5rem"
                  >
                    Select
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    style="padding: 0.5rem 0.5rem"
                  >
                    {{ $t("Name") }}
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    style="padding: 0.5rem 0.5rem"
                  >
                    {{ $t("Created") }}
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    style="padding: 0.5rem 0.5rem"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="powdermodel in previousVisitsPowderModelsOrdered"
                  :key="powdermodel.id"
                >
                  <td class="text-center">
                    <SoftCheckbox
                      :id="`checkbox-${powdermodel.id}`"
                      :name="`checkbox-${powdermodel.id}`"
                      :checked="visit.spraypatternstage.selected_powder_model == powdermodel.id"
                      :disabled="visit.spraypatternstage.selected_powder_model == powdermodel.id"
                      @change="updateSelectedPowderModel(powdermodel.id)"
                    />
                  </td>
                  <td
                    class="text-sm font-weight-normal clickable"
                    :onclick="`window.location='/applications/blueprint/powdermodel/calibrated/${powdermodel.id}'`"
                    style="cursor: pointer; vertical-align: middle"
                  >
                    {{ powdermodel.name }}
                  </td>
                  <td
                    class="text-sm font-weight-normal clickable"
                    :onclick="`window.location='/applications/blueprint/powdermodel/calibrated/${powdermodel.id}'`"
                    style="cursor: pointer; vertical-align: middle"
                  >
                    {{ dateToString(powdermodel.created_at, true) }}
                  </td>
                  <td
                    class="text-sm text-end font-weight-normal clickable"
                    :onclick="`deletePowderModel(${powdermodel.id}, '${powdermodel.name}')`"
                    style="vertical-align: middle"
                  >
                    <a class="btn btn-link text-danger text-gradient px-3 mb-0 my-auto">
                      <i
                        class="far fa-trash-alt me-2"
                        aria-hidden="true"
                      ></i
                      >{{ $t("Delete") }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p class="mt-3 font-light">
              No Spray Patterns calibrated on previous Visits.
            </p>
          </div>
          <div v-if="!sprayPatternIsSelected == 0">
            <p class="text-lg text-center mt-3">
              <b>Please select a Spray Pattern calibration</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateToString, OrderByDateUpdated, useGetVisit } from "@/views/composables.js";
import axios from "axios";
import SoftCheckbox from "@/components/SoftCheckbox.vue";

export default {
  name: "PowderModelListBluePrint",
  components: {
    SoftCheckbox,
  },
  props: {
    lineId: {
      type: String,
      default: null,
    },
    visitId: {
      type: String,
      default: null,
    },
    powderId: {
      type: Number,
      default: null,
    },
  },
  emits: [
    "updated-selected-spraypattern",
  ],
  setup() {
    const { visit, getVisit } = useGetVisit();
    return { visit, getVisit };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      powdermodellist: [],
      powder: {
        selected_powder_model: null,
      },
    };
  },
  computed: {
    sprayPatternIsSelected () {
      if (
        this.visit.spraypatternstage.selected_powder_model == null ||
        this.visit.spraypatternstage.selected_powder_model == undefined
      ) {
        return false;
      }
      return true;
    },
    CurrentVisitPowderModelsOrdered() {
      let current_visit_powder_models = this.OrderByDateUpdated(
          this.powdermodellist.filter(powdermodel => powdermodel.visit == parseInt(this.visitId)),
      );
      return current_visit_powder_models;
    },
    previousVisitsPowderModelsOrdered() {
      let previous_visits_powder_models = this.OrderByDateUpdated(
        this.powdermodellist.filter(powdermodel => powdermodel.visit != parseInt(this.visitId)),
      );
      return previous_visits_powder_models;
    },
  },
  watch: {
    "$store.state.user_data.language"() {
      this.$i18n.locale = this.$store.state.user_data.language;
    },
  },
  mounted() {
    this.getData();
  },
  created() {
    window.deletePowderModel = this.deletePowderModel;
  },
  methods: {
    dateToString,
    OrderByDateUpdated,
    async getData() {
      if (this.powderId !== null) {
        Promise.all([this.getVisit(this.visitId), this.getPowder(), this.getPowderModelList()]);
        this.$store.state.isLoading = false;
      }
    },
    async getPowder() {
      await axios
        .get("/api/v1/blueprint/powder/" + this.powderId + "/")
        .then(response => {
          this.powder = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getPowderModelList() {
      await axios
        .get("/api/v1/fp/powdermodels/lineid/" + this.lineId + "/")
        .then(response => {
          this.powdermodellist = response.data.filter(powdermodel => powdermodel.powder == parseInt(this.powderId));
        })
        .catch(error => {
          console.error(error);
        });
    },
    deletePowderModel(powdermodel_id, powdermodel_name) {
      if (this.visit.spraypatternstage.selected_powder_model == powdermodel_id) {
        this.$swal({
          title: `${this.$t("Error")}`,
          text: `${this.$t(
            "You cannot delete the selected powder model. Create a new powder model and select it before deleting this one.",
          )}`,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.$swal({
        title: `${this.$t("Are you sure you want to delete Powder Model")} ${powdermodel_name}?`,
        text: `${this.$t("You will not be able to revert this!")}`,
        showCancelButton: true,
        confirmButtonText: `${this.$t("Yes, delete it")}`,
        cancelButtonText: `${this.$t("No, cancel")}`,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.state.isLoading = true;
          axios
            .delete("/api/v1/fp/powdermodels/" + powdermodel_id)
            .then(() => {
              this.$store.state.isLoading = false;
              this.powderModelDeletedSweetAlert();
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
    },
    powderModelDeletedSweetAlert() {
      this.$swal({
        title: `${this.$t("Deleted")}`,
        text: `${this.$t("Your powder model has been deleted.")}`,
        icon: "success",
        customClass: {
          confirmButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          window.location.reload(true);
        }
      });
    },
    updateSelectedPowderModel(powdermodel_id) {
      const PROGRESS_STATUS_COMPLETED = "Completed";
      axios
        .patch("/api/v1/blueprint/spraypattern/" + this.visitId + "/", {
          selected_powder_model: powdermodel_id,
          progress_status: PROGRESS_STATUS_COMPLETED,
        })
        .catch(error => {
          console.error(error);
        });
      this.visit.spraypatternstage.selected_powder_model = powdermodel_id;

      this.$emit("updated-selected-spraypattern", powdermodel_id);
    },
  },
};
</script>

<i18n>
  {
    "EN": {
    "Powder Model List for line ":"Powder Model List for line ",
    "Name":"Name",
    "Updated":"Updated",
    "Created":"Created",
    "Remove Powder Model":"Remove Powder Model",
    "No Powder Models created.":"No Powder Models created.",
    "Are you sure you want to delete Powder Model":"Are you sure you want to delete Powder Model",
    "You will not be able to revert this!":"You will not be able to revert this!",
    "Yes, delete it":"Yes, delete it",
    "No, cancel":"No, cancel",
    "Deleted":"Deleted",
    "Your powder model has been deleted.":"Your powder model has been deleted.",
    "Delete":"Delete",
    },
    "DE": {
    "Powder Model List for line ":"Sprühbildliste für inie ",
    "Name":"Name",
    "Updated":"Aktualisiert",
    "Created":"Erstellt",
    "Remove Powder Model":"Pulvermodell entfernen",
    "No Powder Models created.":"Keine Sprühbilder erstellt.",
    "Are you sure you want to delete Powder Model":"Möchten Sie das Pulvermodell wirklich löschen",
    "You will not be able to revert this!":"Sie können dies nicht rückgängig machen!",
    "Yes, delete it":"Ja, lösche es",
    "No, cancel":"Nein, abbrechen",
    "Deleted":"Gelöscht",
    "Your powder model has been deleted.":"Ihr Pulvermodell wurde gelöscht.",
    "Delete":"Löschen",
    },
    "ES": {
    "Powder Model List for line ":"Lista de modelos de polvo para la línea ",
    "Name":"Nombre",
    "Updated":"Actualizado",
    "Created":"Creado",
    "Remove Powder Model":"Eliminar modelo de polvo",
    "No Powder Models created.":"No se ha creado ningún modelo de polvo.",
    "Are you sure you want to delete Powder Model":"¿Estás seguro de que quieres eliminar el modelo de polvo",
    "You will not be able to revert this!":"¡No podrás deshacer esto!",
    "Yes, delete it":"Sí, bórralo",
    "No, cancel":"No, cancelar",
    "Deleted":"Eliminado",
    "Your powder model has been deleted.":"Su modelo de polvo ha sido eliminado.",
    "Delete":"Eliminar",
    },
    "FR": {
    "Powder Model List for line ":"Liste des modèles de poudres pour la ligne ",
    "Name":"Nom",
    "Updated":"Mis à jour",
    "Created":"Créé",
    "Remove Powder Model":"Supprimer le modèle de poudre",
    "No Powder Models created.":"Aucun modèle de poudre n'a été créé.",
    "Are you sure you want to delete Powder Model":"Voulez-vous vraiment supprimer le modèle de poudre",
    "You will not be able to revert this!":"Vous ne pourrez pas revenir en arrière!",
    "Yes, delete it":"Oui, supprimez-le",
    "No, cancel":"Non, annuler",
    "Deleted":"Supprimé",
    "Your powder model has been deleted.":"Votre modèle de poudre a été supprimé.",
    "Delete":"Effacer",
    },
    "IT": {
    "Powder Model List for line ":"Elenco dei modelli di polvere per la linea ",
    "Name":"Nome",
    "Updated":"Aggiornato",
    "Created":"Creato",
    "Remove Powder Model":"Rimuovi modello di polvere",
    "No Powder Models created.":"Nessun modello di polvere creato.",
    "Are you sure you want to delete Powder Model":"Sei sicuro di voler eliminare il modello di polvere",
    "You will not be able to revert this!":"Non sarai in grado di annullare questa operazione!",
    "Yes, delete it":"Sì, cancellalo",
    "No, cancel":"No, annulla",
    "Deleted":"Eliminato",
    "Your powder model has been deleted.":"Il tuo modello di polvere è stato eliminato.",
    "Delete":"Elimina",
    },
    "PL": {
    "Powder Model List for line ":"Lista modeli proszków dla linii ",
    "Name":"Nazwa",
    "Updated":"Zaktualizowano",
    "Created":"Utworzony",
    "Remove Powder Model":"Usuń model proszku",
    "No Powder Models created.":"Nie utworzono modelu proszku.",
    "Are you sure you want to delete Powder Model":"Czy na pewno chcesz usunąć model proszku",
    "You will not be able to revert this!":"Nie będziesz mógł tego cofnąć!",
    "Yes, delete it":"Tak, usuń to",
    "No, cancel":"Nie, anuluj",
    "Deleted":"Usunięty",
    "Your powder model has been deleted.":"Twój model proszku został usunięty.",
    "Delete":"Kasować",
    },
    "TR": {
    "Powder Model List for line ":"Hat için Toz Model listesi ",
    "Name":"İsim",
    "Updated":"Güncellenmiş",
    "Created":"Oluşturuldu",
    "Remove Powder Model":"Toz Modelini Çıkarın",
    "No Powder Models created.":"Toz Modeli oluşturulmadı.",
    "Are you sure you want to delete Powder Model":"Toz Modelini silmek istediğinizden emin misiniz",
    "You will not be able to revert this!":"Bunu geri alamayacaksın!",
    "Yes, delete it":"Evet, sil",
    "No, cancel":"Hayır, iptal et",
    "Deleted":"Silindi",
    "Your powder model has been deleted.":"Toz modeliniz silindi.",
    "Delete":"Sil",
    },
    "ZH": {
    "Powder Model List for line ":"生产线粉末模型列表 ",
    "Name":"产品名称",
    "Updated":"已更新",
    "Created":"已创建",
    "Remove Powder Model":"删除粉末模型",
    "No Powder Models created.":"未创建粉末模型",
    "Are you sure you want to delete Powder Model":"您确定要删除粉末模型吗",
    "You will not be able to revert this!":"您将无法撤消此操作！",
    "Yes, delete it":"是的，删除它",
    "No, cancel":"不，取消",
    "Deleted":"已删除",
    "Your powder model has been deleted.":"您的粉末模型已被删除。",
    "Delete":"删除",
    }
  }
</i18n>
