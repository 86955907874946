<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="col-12">
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex mb-2 flex-column card card-body blur shadow-blur">
            <div class="card-header mb-0 text-center">
              <h3 class="font-weight-bolder text-success text-gradient">coatingAI FineTune</h3>
              <h5 class="mb-0">
                for Line <i>{{ line.name }}</i>
              </h5>
            </div>
          </div>
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex flex-column card card-body blur shadow-blur">
            <div
              v-if="selected_powder"
              class="card-header row"
            >
              <div class="mt-3">
                <h4 class="text-center mt-1 ms-4">Guns Equalization</h4>
                <div class="justify-content-left mt-4 ms-4 mb-4">
                  <hr class="horizontal dark" />
                  <label class="mt-2 mb-2 h6"> Selected Powder </label>
                  <div class="mt-2 mb-2 mx-2">
                    {{ selected_powder.name }}
                  </div>
                </div>
                <div
                  class="card-body"
                  style="padding-top: 0.2rem"
                >
                  <div class="row mb-4">
                    <h5 class="mt-2">Line Layout</h5>
                    <div class="d-flex justify-content-center">
                      <canvas
                        id="lineLayoutCanvas"
                        class="col-12 mt-0 mb-4"
                        width="700"
                        height="300"
                        :style="`max-width: 1280px; ${line.id !== null ? '' : 'display: none;'}`"
                      >
                      </canvas>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <h5>Powder Amount Parameters</h5>
                    <div class="row">
                      <div class="col-4">
                        <label> Minimum Powder Amount </label>
                      </div>
                      <div class="col-4">
                        <label> Maximum Powder Amount </label>
                      </div>
                      <div class="col-4">
                        <label> Time Interval [s]</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">&nbsp; {{ min_powder_amount_setting }}</div>
                      <div class="col-4">&nbsp; {{ max_powder_amount_setting }}</div>
                      <div class="col-4">&nbsp; {{ time_interval }} s</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h5 class="text-start mb-2">Powder Output Measurements</h5>
                  </div>
                  <div style="overflow-x: auto">
                    <div class="table-responsive">
                      <table
                        class="table table-sm text-start"
                        style="border-width: 0 !important"
                      >
                        <thead>
                          <tr>
                            <th>Gun Name</th>
                            <th>Empty bag weight</th>
                            <th
                              v-for="index in maxPairs"
                              :key="'header-' + index"
                            >
                              Setting {{ index }} Weight {{ index }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(gun, gunIndex) in powderoutput_measurements"
                            :key="gunIndex"
                          >
                            <td class="text-center">
                              <div>
                                <p>{{ gunNamesExist(gun_names) ? gun_names[gunIndex] : gunIndex + 1 }}</p>
                              </div>
                            </td>
                            <td>
                              <div v-if="editablePowderOutputMeasurements">
                                <input
                                  v-model="gun.empty_bag_weight"
                                  type="number"
                                  class="form-control form-control-sm"
                                  min="0"
                                  :placeholder="`Bag weight [${$store.state.units_system[$store.state.user_data.unit_system].grams}] `"
                                  style="max-width: 190px"
                                  @keydown="numericPositiveOnly"
                                  @change="
                                    updated_powder_output_measurements = true;
                                    is_calculation_completed = false;
                                    powderoutput_measurements = autoFillInputColumn(
                                      powderoutput_measurements,
                                      gunIndex,
                                      'empty_bag_weight',
                                    );
                                    saveFormProgress();
                                  "
                                />
                              </div>
                              <div v-else>
                                {{ gun.empty_bag_weight }}
                              </div>
                            </td>
                            <td
                              v-for="index in maxPairs"
                              :key="'pair-' + gunIndex + '-' + index"
                            >
                              <div
                                v-if="gun.gun_measurements[index - 1]"
                                class="d-flex"
                              >
                                <div style="width: 70px">
                                  {{ gun.gun_measurements[index - 1].setting }}
                                </div>
                                <div v-if="editablePowderOutputMeasurements">
                                  <input
                                    v-model="gun.gun_measurements[index - 1].weight"
                                    type="number"
                                    class="form-control form-control-sm me-2"
                                    placeholder="Weight"
                                    min="0"
                                    style="width: 90px"
                                    :disabled="!editablePowderOutputMeasurements"
                                    @keydown="numericPositiveOnly"
                                    @blur="PowderAmountWeightCheck($event.target.value, gunIndex)"
                                    @change="
                                      updated_powder_output_measurements = true;
                                      is_calculation_completed = false;
                                      saveFormProgress();
                                    "
                                  />
                                </div>
                                <div v-else>
                                  {{ gun.gun_measurements[index - 1].weight }}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    v-if="gunsEqualizationCompleted && !updated_powder_output_measurements"
                    class="row"
                  >
                    <div class="col-lg-10">
                      <h5 class="">Powder Output Chart</h5>
                      <guns-equalization-chart />
                    </div>
                    <gun-throughput
                      title="Guns Equalization"
                      :gun-throughput="last_powder_throughput ? parseFloat(last_powder_throughput.toFixed(1)) : 0"
                      :line="line"
                      :powder-amount-parameters="powder_amount_parameters"
                    />
                  </div>
                  <div
                    v-if="!gunsEqualizationCompleted || updated_powder_output_measurements"
                    class="col-12 text-center mt-4 mb-4"
                  >
                    <button
                      type="button"
                      class="mt-4 mb-2 text-center btn"
                      :class="emptyPowderOutputMeasurements ? 'bg-gradient-secondary' : 'bg-gradient-success'"
                      style="width: 90%"
                      @click="calculateGunsEqualization()"
                    >
                      Calculate Powder Amount adjustments
                    </button>
                  </div>
                  <div
                    v-if="!updated_powder_output_measurements && gunsEqualizationCompleted"
                    class="col-12 text-end mt-4 mb-4"
                  >
                    <soft-button
                      type="button"
                      class="mb-0 ms-auto js-btn-next w-20"
                      style="width: 50%; min-width: 160px; max-width: 200px"
                      :disabled="!gunsEqualizationCompleted"
                      @click="
                        $router.push({
                          name: 'FineTuneDashboard',
                          params: { lineId: line.id, gunsEqualizationId: gunsEqualizationId },
                        })
                      "
                    >
                      Go Back &nbsp; &nbsp;
                    </soft-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import axios from "axios";
import eventBus from "@/views/applications/blueprint/utils/eventBus.js";
import setTooltip from "@/assets/js/tooltip.js";

import { 
  autoFillInputColumn,
  generateMeasurementList,
  gunNamesExist,
  numericPositiveOnly,
  roundPowderAmountParameters,
} from "@/views/composables.js";
import GunsEqualizationChart from "../components/GunsEqualizationChart.vue";
import GunThroughput from "../components/GunThroughput.vue";
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "GunsEqualizationFinetune",
  components: {
    GunsEqualizationChart,
    GunThroughput,
    SoftButton,
  },
  props: {
    fromHistory: {
      type: String,
      default: "true",
    },
    lineId: {
      type: String,
      default: "",
    },
    gunsEqualizationId: {
      type: String,
      default: "",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      powderoutput_measurements: [
        {
          empty_bag_weight: null,
          gun_measurements: [
            {
              setting: "",
              weight: "",
            },
            {
              setting: "",
              weight: "",
            },
          ],
        },
      ],
      line: {
        name: "",
        total_pistols: [0],
      },
      powders: [],
      selected_powder: null,
      min_powder_amount_setting: 0,
      max_powder_amount_setting: 200,
      time_interval: 60,
      updated_powder_output_measurements: false,
      is_calculation_completed: false,
      last_powder_throughput: null,
      powder_amount_parameters: [],
      // Line Layout image variables
      canvas_width: null,
      canvas_height: null,
      ctx_line_layout: null,
      line_layout_image: [],
      gun_names: [],
    };
  },
  computed: {
    gunsEqualizationCompleted() {
      let guns_equalization_completed =
        this.selected_powder !== null &&
        !this.emptyPowderOutputMeasurements &&
        this.expected_powder_per_minute != null &&
        this.is_calculation_completed == true;

      return guns_equalization_completed;
    },
    maxPairs() {
      let maxPairs = 0;
      this.powderoutput_measurements.forEach(gun => {
        if (gun.gun_measurements.length > maxPairs) {
          maxPairs = gun.gun_measurements.length;
        }
      });
      return maxPairs;
    },
    emptyPowderOutputMeasurements() {
      const empty_powder_output_measurements = this.powderoutput_measurements.some(gun =>
        gun.gun_measurements.some(pair => pair.setting == "" || pair.weight == ""),
      );
      return empty_powder_output_measurements;
    },
    editablePowderOutputMeasurements() {
      return this.fromHistory == "false";
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    autoFillInputColumn,
    numericPositiveOnly,
    generateMeasurementList,
    gunNamesExist,
    roundPowderAmountParameters,
    async getData() {
      await this.getFineTuneLine();
      this.gun_names = this.line.gun_names;
      await this.getPowdersFineTune();
      await this.getGunsEqualizationData();

      if (this.selected_powder !== null) {
        this.showLineLayout();
      }

      // if (this.emptyPowderOutputMeasurements) {
      //   console.log("populating powder output measurements");
      //   this.populatePowderOutputMeasurements();
      // }

      if (!this.emptyPowderOutputMeasurements && this.gunsEqualizationCompleted) {
        await this.computeGunsEqPowderAmountParams();
        await this.showGunsEqualizationGraph();
        this.updated_powder_output_measurements = false;
      }
    },
    async getLatestVisitData() {
      if (this.selected_powder == null) {
        return;
      }
      try {
        const response = await axios.get(
          "api/v1/finetune/getinitialdata/" + this.lineId + "/" + this.selected_powder.id + "/",
        );

        this.expected_powder_per_minute = response.data.last_powder_throughput;
        this.last_powder_throughput = JSON.parse(JSON.stringify(response.data.last_powder_throughput));
        this.min_powder_amount_setting = response.data.min_powder_amount_setting;
        this.max_powder_amount_setting = response.data.max_powder_amount_setting;
        this.time_interval = response.data.time_interval;
        this.last_thickness_measurements = response.data.last_thickness_measurements;
        this.powderoutput_measurements = this.clearWeightMeasurements(response.data.powder_output_measurements);
      } catch (error) {
        console.error(error);
        if (error.response && (error.response.status === 404 || error.response.status === 500)) {
          this.$swal({
            title: "Powder Throughput could not be retrieved.",
            text: error.response.data.error,
            icon: "error",
            confirmButtonText: "OK",
          });
          this.selected_powder = null;
          this.saveFormProgress();
        }
      }
    },
    async calculateGunsEqualization() {
      if (this.emptyPowderOutputMeasurements) {
        this.$swal({
          title: "Empty Measurements",
          text: "Please fill all the Powder Output Measurement fields",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      this.is_calculation_completed = true;
      this.updated_powder_output_measurements = false;

      await this.computeGunsEqPowderAmountParams();
      await this.showGunsEqualizationGraph();

      await this.saveFormProgress();
      this.successSwal();
    },
    clearWeightMeasurements(powderoutput_measurements) {
      const powderoutput_measurements_cleared_weights = powderoutput_measurements.map(gun => ({
        empty_bag_weight: null,
        gun_measurements: gun.gun_measurements.map(pair => ({
          setting: pair.setting,
          weight: "",
        })),
      }));

      return powderoutput_measurements_cleared_weights;
    },
    async getGunsEqualizationChartDataset() {
      let body = {
        min_powder_amount_setting: this.min_powder_amount_setting,
        max_powder_amount_setting: this.max_powder_amount_setting,
        time_interval: this.time_interval,
        powderoutput_measurements: this.powderoutput_measurements.map(gun => ({
          ...gun,
          empty_bag_weight: gun.empty_bag_weight == null || gun.empty_bag_weight === "" ? 0 : gun.empty_bag_weight,
        })),
        cumulative_powderoutput_measurements: true,
      };

      const response = await axios.post("/api/v1/blueprint/gunsequalizationchart/", body);
      this.$store.state.gunsEqualizationChart = response.data.powderoutput_measurements;

      if (gunNamesExist(this.gun_names)) {
        this.$store.state.gunsEqualizationChart.datasets.forEach((dataset, index) => {
          dataset.label = "Gun " + this.gun_names[index];
        });
      }
    },
    async computeGunsEqPowderAmountParams() {
      if (this.emptyPowderOutputMeasurements) {
        return;
      }

      try {
        let body = {
          line: this.lineId,
          time_interval: this.time_interval,
          measures_list: this.generateMeasurementList(this.powderoutput_measurements),
          powder_per_minute: this.expected_powder_per_minute,
          gun_idx: null,
          powder_amount_param: null,
          cumulative_powderoutput_measurements: true,
        };

        let response = await axios.post("/api/v1/fp/computepowderamountcalibration/", body);
        this.powder_amount_parameters = response.data.powder_amount_params;

        this.powder_amount_parameters = this.roundPowderAmountParameters(this.powder_amount_parameters);
      } catch (error) {
        console.error(error);
      }
    },
    populatePowderOutputMeasurements() {
      this.powderoutput_measurements = this.powderoutput_measurements.map(gun => ({
        empty_bag_weight: gun.empty_bag_weight == null || gun.empty_bag_weight === "" ? 17 : gun.empty_bag_weight,
        gun_measurements: gun.gun_measurements.map(pair => ({
          setting: pair.setting,
          weight: pair.setting ? 5 * pair.setting : "",
        })),
      }));
    },
    async getFineTuneLine() {
      try {
        const line_response = await axios.get("api/v1/finetune/line/" + this.lineId + "/");

        line_response.data.total_pistols = JSON.parse(line_response.data.total_pistols);
        line_response.data.pistol_to_pistol_distance = JSON.parse(line_response.data.pistol_to_pistol_distance);
        line_response.data.pistol_columns_distances = JSON.parse(line_response.data.pistol_columns_distances);
        line_response.data.pistol_columns_vertical_offsets = JSON.parse(
          line_response.data.pistol_columns_vertical_offsets,
        );

        line_response.data.pistols_max_movement_range = line_response.data.pistols_max_movement_range * 100;

        this.line = line_response.data;
        this.$store.state.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getGunsEqualizationData() {
      if (!this.$route.params.gunsEqualizationId) {
        return;
      }

      try {
        const response = await axios.get("api/v1/finetune/gunsequalization/" + this.$route.params.gunsEqualizationId);

        if (response.data.selected_powder !== null) {
          this.selected_powder = this.powders.find(powder => powder.id == response.data.selected_powder);
        }

        if (response.data.powder_output_measurements !== null) {
          this.powderoutput_measurements = response.data.powder_output_measurements;
        }

        if (response.data.last_thickness_measurements !== null) {
          this.last_thickness_measurements = response.data.last_thickness_measurements;
        }

        if (response.data.min_powder_amount_setting !== null) {
          this.min_powder_amount_setting = response.data.min_powder_amount_setting;
        }

        this.expected_powder_per_minute = response.data.powder_throughput_per_gun;
        this.last_powder_throughput = response.data.powder_throughput_per_gun;
        this.lastThicknessAdjustmentIterationId = response.data.last_thickness_adjustment_iteration;
        this.max_powder_amount_setting = response.data.max_powder_amount_setting;
        this.is_calculation_completed = response.data.is_calculation_completed;
      } catch (error) {
        console.error(error);
      }
    },
    async getPowdersFineTune() {
      try {
        const response = await axios.get("api/v1/finetune/powdersbyline/" + this.lineId + "/");
        this.powders = response.data;

        if (this.powders.length == 1 && this.selected_powder == null) {
          this.selected_powder = this.powders[0];
          await this.getLatestVisitData();
          await this.getGunsEqualizationData();
          this.saveFormProgress();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveFormProgress() {
      if (!this.gunsEqualizationId) {
        return;
      }

      try {
        const dataForm = {
          selected_powder: this.selected_powder !== null ? this.selected_powder.id : null,
          max_powder_amount_setting: this.max_powder_amount_setting,
          min_powder_amount_setting: this.min_powder_amount_setting,
          is_calculation_completed: this.is_calculation_completed,
          current_active_page: 0,
          time_interval: this.time_interval,
          last_thickness_measurements: this.last_thickness_measurements,
          last_powder_throughput: this.last_powder_throughput,
          powder_output_measurements: this.powderoutput_measurements,
          powder_throughput_per_gun: this.expected_powder_per_minute,
        };

        await axios.patch(`/api/v1/finetune/gunsequalization/` + this.gunsEqualizationId + `/`, dataForm);
      } catch (error) {
        console.error("Error saving form progress:", error);
      }
    },
    async successSwal() {
      try {
        await this.$swal({
          title: "Gun Equalization success",
          text: "Powder amounts fine tune adjustment calculation successful",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error(error);
      }
    },
    async showGunsEqualizationGraph() {
      await this.getGunsEqualizationChartDataset();

      eventBus.emit("draw-gunsequlization-chart");
    },
    async showLineLayout() {
      nextTick(() => {
        var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
        this.canvas_width = lineLayoutCanvas.width;
        this.canvas_height = lineLayoutCanvas.height;
        this.ctx_line_layout = lineLayoutCanvas.getContext("2d");

        this.getLineLayoutImage().then(() => {
          this.fillLineLayoutCanvas();
        });
      });
    },
    async getLineLayoutImage() {
      try {
        var body = {
          line: this.lineId,
          canvas_width: this.canvas_width,
          canvas_height: this.canvas_height,
        };

        var response = await axios.post("/api/v1/fp/computepowderamountlineimage/", body);
        this.line_layout_image = response.data["layout_image"];
      } catch (error) {
        console.error(error);
      }
    },
    fillLineLayoutCanvas() {
      let line_layout_imageData = new ImageData(
        Uint8ClampedArray.from(this.line_layout_image.values()),
        this.canvas_width,
        this.canvas_height,
      );
      this.ctx_line_layout.putImageData(line_layout_imageData, 0, 0);
    },
    PowderAmountWeightCheck(target_value, gun_index) {
      if (
        this.powderoutput_measurements[gun_index].gun_measurements.some(
          pair => pair.weight !== "" && pair.weight <= this.powderoutput_measurements[gun_index].empty_bag_weight,
        )
      ) {
        this.$swal({
          title: "Invalid Powder Output Measurement",
          text: "The Powder Output measurement must be higher than the empty bag weight.",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderWeightInput(target_value, gun_index));
        return;
      } else if (
        this.powderoutput_measurements[gun_index].gun_measurements.some(
          (pair, index, array) => index > 0 && pair.weight !== "" && pair.weight <= array[index - 1].weight,
        )
      ) {
        this.$swal({
          title: "Invalid Powder Output Measurement",
          text: "Each Powder Output Measurement must be higher than the previous one.",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => this.removePowderWeightInput(target_value, gun_index));
        return;
      }
    },
    removePowderWeightInput(target_value, gun_index) {
      this.powderoutput_measurements[gun_index].gun_measurements.map(pair => {
        if (pair.weight == target_value) {
          pair.weight = "";
        }
      });
    },
  },
};
</script>
<style scoped>
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.container-fluid {
  padding-top: 20px;
}
</style>
